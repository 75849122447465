import { Table } from "antd"
import queryString from "query-string"
import React, { useEffect, useState } from "react"
import { Spinner } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import strings from "../../../../../translation"
import Pagination from "../../../../SuperAdmin/Feedback/components/Pagination"
import { getTrainingSchedule } from "../../redux/actions"
import "./style.scss"
import moment from "moment"
import { getLanguage } from "../../../../../utils/functions"


const TrainingScheduleTable = () => {
  const paginatedTrainingData = useSelector(state => state.Home.trainingSchedule)
  const { users } = useSelector(state => state.login.loginInfo)
  const [companyId, setCompanyId] = useState();
 // console.log("DATATA", users.employee_company_detail[0].company, paginatedTrainingData)
  const [trainingData, setTrainingData] = useState([])



  useEffect(()=>{
    if(users.user_type){
      if(users.user_type === "company"){
       setCompanyId(users.company_detail[0].id)
      }else if(users.user_type === "business_admin"){
        setCompanyId(users.employee_company_detail[0].company)
      }
    }
  },[users])



  useEffect(() => {
    if (paginatedTrainingData.results && paginatedTrainingData.results.length > 0) {

      const temp = paginatedTrainingData.results.map(item=>{
        if(!item.meeting_date){
          const daysOffset = !isNaN(Number(item.company_plan_days)) ? Number(item.company_plan_days) : 1;
          const diff = item.video_number - item.first_video_number;
          const firstMeetingDate = item.first_meeting_date;
          const baseDate = moment(firstMeetingDate, "YYYY-MM-DD")
          const daysFuture = daysOffset * diff;
          return {
            ...item,
            meeting_date : baseDate
            .clone()
            .add(daysFuture, "days")
            .format("YYYY-MM-DD")
          }
        }
        return item
      })

      // Set the modified data
      setTrainingData(temp)
    }
  }, [paginatedTrainingData])


  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const [pageSize, setPageSize] = useState(
    queryParams.page_size ? JSON.parse(queryParams.page_size) : 8
  )
  const [page, setPage] = useState(
    queryParams.page ? JSON.parse(queryParams.page) : 1
  )
  const loading = useSelector(state => state.Home.getloading)

  const [currentLanguage, setCurrentLanguage] = useState("")
  useEffect(() => {
    getLanguage().then(lan => setCurrentLanguage(lan))
  }, [])

  useEffect(() => {
    // if (users.company_detail[0].id && currentLanguage?.length > 0) {
    //   dispatch(
    //     getTrainingSchedule(
    //       users.company_detail[0].id,
    //       pageSize,
    //       page,
    //       currentLanguage
    //     )
    //   )
    //   history.push(`/company/company-home/?page_size=${pageSize}&page=${page}`)
    // }
    // if (users.employee_company_detail[0].company && currentLanguage?.length > 0) {
    //   dispatch(
    //     getTrainingSchedule(
    //       users.employee_company_detail[0].company,
    //       pageSize,
    //       page,
    //       currentLanguage
    //     )
    //   )
    //   history.push(`/company/company-home/?page_size=${pageSize}&page=${page}`)
    // }
    if (companyId && currentLanguage?.length > 0) {
      dispatch(
        getTrainingSchedule(
          companyId,
          pageSize,
          page,
          currentLanguage
        )
      )
      history.push(`/company/company-home/?page_size=${pageSize}&page=${page}`)
    }
  }, [companyId, page, pageSize, currentLanguage])

  const columns = [
    {
      title: `${strings.SAFETY_TRAINING_TOPIC}`,
      dataIndex: "topic_name",
      key: "topic_name"
    },
    {
      title: `${strings.DATE}`,
      dataIndex: "schedule_date",
      key: "schedule_date"
    },
    {
      title: `${strings.STATUS}`,
      dataIndex: "status",
      key: "status",
      render: status => {
        if (status.is_completed) {
          return (
            <span className="statusContainer completeContainer">
              {strings.COMPLETED}
            </span>
          )
        } else {
          return (
            <span className="statusContainer scheduleContainer">
              {strings.SCHEDULED}
            </span>
          )
        }
      }
    }
  ]
  function processTitle(title) {
    if (!title) return "-"

    // Remove parentheses and their contents, but keep the rest of the string
    title = title.replace(/^\(|\)$/g, "").trim()

    // Check for the first occurrence of '-'
    const dashIndex = title.indexOf("-")
    if (dashIndex !== -1) {
      title = title.substring(0, dashIndex).trim()
    } else {
      // If no '-', check for the first occurrence of '#'
      const hashIndex = title.indexOf("#")
      if (hashIndex !== -1) {
        title = title.substring(0, hashIndex).trim()
      }
    }

    // If the resulting title is empty, return a default value
    return title || "-"
  }

  const data = []
  for (let i = 0; i < trainingData.length; ++i) {
    data.push({
      key: i.toString(),
      topic_name: processTitle(trainingData[i]?.title),
      schedule_date:
        moment(trainingData[i].meeting_date, "YYYY-MM-DD")
          .locale(currentLanguage)
          .format(currentLanguage === "en" ? "MMMM Do YYYY" : "MMMM D YYYY") ||
        "-",
      status: {
        is_completed: trainingData[i].is_completed
      }
    })
  }

  return (
    <div className="site-table-wrapper2">
      <div className="site-table-head-wrapper">
        <div className="site-table-head flex-align-between">
          <div className="title">{strings.SAFETY_TRAINING_SCHEDULE}</div>
        </div>
      </div>
      {loading ? (
        <div className="spinner-wrapper">
          <Spinner as="div" animation="border" size="xl" />
        </div>
      ) : data.length ? (
        <>
          <Table columns={columns} dataSource={data} pagination={false} />
          <Pagination
            setPage={setPage}
            setPageSize={setPageSize}
            rowsPerPage={pageSize}
            currentPage={page}
            totalPages={paginatedTrainingData.total_pages}
          />
        </>
      ) : (
        <div className="nodata-wrapper">{strings.NO_DATA_FOUND}</div>
      )}
    </div>
  )
}
export default TrainingScheduleTable
